import {AppError} from '~/components/AppErrorBoundary';
import type {Sync} from '~/features/observatory-model.server';
import {SyncStatus} from '~/features/observatory-model.server';

export function assertWithResponse(condition: any, message: string, responseInit?: ResponseInit): asserts condition {
  if (!condition) {
    throw new Response(message, responseInit);
  }
}

export function assertAllowedMethod(request: Request, ...methods: string[]) {
  assertWithResponse(methods.includes(request.method), 'Method Not Allowed', {status: 405});
}

export function assertFinishedSync(sync: Sync | null | undefined): asserts sync {
  assertWithResponse(sync?.status === SyncStatus.FINISHED, AppError.SYNC_NOT_FOUND, {status: 404});
}
